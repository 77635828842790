$(document).ready(function () {

    $('body').on('click', '.js-come-back-link', function (e) {
        e.preventDefault();
        history.back();
    });

    scrollHeader();

    setTimeout(function () {
        $('[data-role="headroom"]').headroom({
            offset: $('[data-role="headroom"]').height()
        });
    }, 1000);

    initPlugins();

    $('[data-role="menu-more"]').menuMore();
    $('[data-toggle="tooltip"]').tooltip();

    $('[data-role="styler"]').styler({
        onSelectClosed: function () {
            $(this).closest('.form__group').css({'z-index': 'auto'});
            $(this).closest('.form__input').css({'z-index': 'auto'});
        },
        onSelectOpened: function () {
            $(this).closest('.form__group').css({'z-index': 10001});
            $(this).closest('.form__input').css({'z-index': 10001});
        }
    });


    $('[data-role="datepicker"]').datetimepicker({
        locale: 'ru',
        format: 'L',
        // debug: true
    });

    $(document).on('click', '[data-role="footer-menu-toggle"]', function (e) {
        e.preventDefault();
        var $this = $(this),
            $parent = $this.closest('.footer-menu'),
            $wrap = $parent.find('.footer-menu__list:not(.footer-menu__list--sub)');

        $parent.toggleClass('is-open');
        $wrap.slideToggle();
    });


    $(document).on('click', '[data-role="menu-sections-toggle"]', function (e) {
        e.preventDefault();
        $('[data-role="menu-sections"]').slideToggle();
    });

    $('.js-floating').each(function () {
        if ($(this).val() !== '') {

            $(this).parent().addClass('active');
        }
    });


    $(document).on('focus', '.js-floating', function () {

        $(this).parent().addClass('active');
        // $(this).closest('.form__group').css({'z-index': 999});
    }).on('blur', '.js-floating', function () {
        //костыль для селекта
        if ($(this).hasClass('active')) {
            $(this).parent().addClass('active');
            return true;
        }
        if ($(this).val() !== '') {

            $(this).parent().addClass('active');
        } else {

            $(this).parent().removeClass('active');
            // $(this).closest('.form__group').css({'z-index': 'auto'});
        }

    });

    $(document).on('click', '[data-role="file-link"]', function (e) {
        e.preventDefault();
        $(this).parent().find('[data-role="insert-file"]').trigger('click');
    });
    $(document).on('keyup blur', '[data-role="file-name"]', function (e) {
        e.preventDefault();
        $(this).parent().find('[data-role="file-path"]').val($(this).val());
    });
    $(document).on('change', '[data-role="insert-file"]', function (e) {
        var $this = $(this),
            $form = $this.closest('form');
        $.ajax({
            url: '/ajax.php?action=UPLOAD_FILE',
            type: 'POST',
            dataType: 'json',
            data: $form.serializefiles(),
            processData: false,
            contentType: false,
            success: function (data) {
                if (!data.success) return false;
                $form.find('[data-role="file-path"]').val(data.url);
                $this.closest('[data-role="file-container"]').addClass('active').find('[data-role="file-name"]').val(data.name);
                $this.parent().find('[data-role="file-link"]').addClass('disabled');
                $this.parent().find('[data-role="file-delete"]').addClass('active');
            }
        });
    });
    $(document).on('click', '[data-role="file-delete"]', function (e) {
        var $this = $(this),
            $form = $this.closest('form');
        $form.find('[data-role="file-path"]').val('');
        $this.removeClass('active')
            .closest('[data-role="file-container"]').removeClass('active')
            .find('[data-role="file-name"]').val('')
            .find('[data-role="insert-file"]').replaceWith($('[data-role="insert-file"]').val('').clone(true));
        $this.prev('[data-role="file-link"]').removeClass('disabled');
    });

    $('.panel-collapse').on('show.bs.collapse', function () {
        $(this).parent().addClass('active')/*.siblings().removeClass('active')*/;
    });
    $('.panel-collapse').on('hide.bs.collapse', function () {
        $(this).parent().removeClass('active');
    });

    /*About promo slider*/

    $('[data-role="about-main-slick"]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        asNavFor: '[data-role="about-sub-slick"]'
    });

    $('[data-role="about-sub-slick"]').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        focusOnSelect: true,
        asNavFor: '[data-role="about-main-slick"]',
        prevArrow: '<a class="about-promo__arrow about-promo__arrow--prev" href="javascript:;"><i class="icon icon--arrow-left-blue"></i></a>',
        nextArrow: '<a class="about-promo__arrow about-promo__arrow--next" href="javascript:;"><i class="icon icon--arrow-right-blue"></i></a>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    /*Vacancy show*/

    $(document).on('click', '[data-role="show-vacancy-content"]', function (e) {
        e.preventDefault();
        var count = $(this).data('count'),
            id = $(this).data('id');
        if (count > 0) {
            $('[data-role="show-vacancy-content"]').removeClass('active');
            $('[data-role="vacancy-content"]').addClass('active');
            $(this).addClass('active');
            $(this).closest('[data-role="vacancy-menu"]').addClass('active');
            $('[data-role="vacancy-pane"]').removeClass('active');
            $('[data-role="vacancy-pane"][data-id="' + id + '"]').addClass('active');
        }
    });

    /*Reviews show*/

    $(document).on('click', '[data-role="show-reviews-full"]', function (e) {
        e.preventDefault();
        $(this).closest('.reviews__item').toggleClass('active');
        $('span', this).toggleClass('hide');
        $(this).closest('.reviews__item').find('.reviews__entry').slideToggle();
        $(this).closest('.reviews__item').find('.reviews__detail').slideToggle();
    });

    $(document).on('click', '[data-role="show-reviews-response"]', function (e) {
        e.preventDefault();
        $('span', this).toggleClass('hide');
        $(this).closest('.reviews__item').find('.reviews__response').slideToggle(0);
    });


    $(document).on("click", '[data-role="scroll-to"], .js-scroll-to', function (e) {
        var $this = $(this),
            target = $this.attr("href") || $this.attr('data-target'),
            duration = $this.attr('data-duration') || 500,
            $el = $(target),
            top = $el.offset().top - $('[data-role="header"]').height();

        $("html, body").animate({scrollTop: top}, duration);
        e.preventDefault();
    });

    $(document).on('click', '[data-role="ajax-nav"]', function (e) {
        e.preventDefault();
        var $this = $(this);
        var nextPage = $this.attr("data-next-page");
        var id = $this.attr("data-catalog-more");

        var data = {};
        data['PAGEN_' + id] = nextPage;
        data['AJAX'] = "Y";
        $.ajax({
            type: "GET",
            url: window.location.href,
            data: data,
            success: function (content) {
                var $content = $(content);
                $('[data-role="ajax-items"]').append($content.find('[data-role="ajax-items"]').html());
                $('[data-role="ajax-nav-container"]').html($content.find('[data-role="ajax-nav-container"]').html());
            }
        });
    });

    $(document).on('submit', '[data-role="form-submit"]', function (e) {
        e.preventDefault();
        var $form = $(this);
        if (!myValidateForm($form)) {
            return false;
        }
        var dataForm = $form.serializeObject();
        SendAjax("SEND_FORM", dataForm, function (data) {
            MSModal.s_form = "#msnet-modal";
            if (data.html) {
                MSModal.hide();
            }
            setTimeout(function () {
                if (data.html) {
                    MSModal.setContent(data.html);
                    MSModal.show();
                }
                if (data.restart === "Y") {
                    MSModal.restartOnHide();
                }
                if (data.ajax_sub_content) {
                    MSModal.setSubContent(data.ajax_sub_content);
                }
            }, 500);
        });
    });
    $(document).on('change', 'select[name="UF_FAV_SERVICE"]', function (e) {

    });

    $('body').on('click', '[data-role="show-more-service-price"]', function () {
        var self = $(this);
        var priceItems = $('.js-additition-price-row');
        if (priceItems.length > 0) {
            priceItems.fadeToggle(300);
            setTimeout(function () {
                self.toggleClass('opened')
            }, 150)
        }
    });


});

$(window).scroll(function () {
    scrollHeader();
});

$(window).resize(function () {
    scrollHeader();
});

function scrollHeader() {
    if ($(window).scrollTop() > 0) {
        $('[data-role="headroom"]').addClass('is-pinned');
    } else {
        $('[data-role="headroom"]').removeClass('is-pinned');
    }
}

var Promo = (function () {
    var $slider = $('[data-role="promo-slick"]');
    var playright = false;
    var video = $slider.find('video').length !== 0 ? $slider.find('video') : false;
    var videoLoaded = false;

    var loadVideo = function () {
            var videoSrc = $('video source[data-src]');
            var indx = 0;

            var videoLoad = function () {
                if (indx < videoSrc.length) {
                    var itm = $(videoSrc.get(indx));
                    itm.closest('video').bind('loadeddata', function () {
                        if (indx == (videoSrc.length - 1)) {
                            setTimeout(function () {
                                playVideo($(videoSrc.get(0)).closest('video'));
                            });
                            videoLoaded = true;
                        }
                        videoLoad(indx++);
                    });

                    itm.attr('src', itm.attr('data-src'));
                    itm.closest('video').load();
                    itm.removeAttr('data-src');
                }

            };

            videoLoad();

        }
    ;


    if (video) {
        $slider.find('video').get(0).volume = 0;
    }

    var slider = function () {

        if (video) {
            $slider
                .on('init', function (slick) {
                    // if ($slider.find('video').get(0).length !== 0) {
                    //     $slider.find('video').get(0).play();
                    // }
                })
                .on('afterChange', function (_, slick, currentSlide) {
                    var $curSlide = $(slick.$slides.get(currentSlide));
                    if ($curSlide.find('video').length !== 0 && videoLoaded) {
                        $slider.slick('slickPause');
                        playVideo($curSlide.find('video'));
                    }
                });
        }

        var options = {
            autoplay: !video,
            dots: true,
            arrows: true,
            slidesToShow: 1,
            fade: true,
            touchMove: false,
            cssEase: 'linear',
            appendArrows: $(".promo__buttons"),
            appendDots: $(".promo__dots"),
        };

        $slider.slick(options);

        if (video) {
            video.bind('ended', function () {
                $slider.slick('slickNext');
                $slider.slick('slickPlay');
            });
        }
    };

    var playVideo = function (el) {
        if (playright) {
            stopVideo();
        }
        el.get(0).play();
        playright = el;
    };

    var stopVideo = function () {
        playright.get(0).pause();
        playright.get(0).currentTime = 0;
    };

    var slickGoToPause = function (index) {
        $slider.slick("slickPause");
        $slider.slick("slickGoTo", index);
    };

    var slickGoToPlay = function (index) {
        $slider.slick("slickGoTo", index);
        $slider.slick("slickPlay");
    };

    var init = function () {
        slider();
    };

    return {
        init: init,
        loadVideo: loadVideo,
        slickGoToPause: slickGoToPause,
        slickGoToPlay: slickGoToPlay
    }
})
();

$(function () {
    if ($('[data-role="promo-slick"]').length !== 0) {
        Promo.init();
    }
});

$(function () {
    var SliderService = $('[data-role="slider-service-slick"]');
    if (SliderService.length > 0) {
        SliderService.slick({
            autoplay: true,
            dots: true,
            arrows: false,
            slidesToShow: 1,
            fade: true,
            touchMove: false
        });
    }
    var SliderServiceSert = $('.js-certificate-slider');
    if (SliderServiceSert.length > 0) {
        SliderServiceSert.slick({
            autoplay: true,
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 4,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
    $(".service-sert__slider-item").fancybox({
        'opacity': true,
        'overlayShow': false,
        'transitionIn': 'elastic',
        'transitionOut': 'none'
    });


});

var ConsultWidget = (function () {

    var widget = $('[data-role="consult-btn"]');

    var init = function () {
        if (widget.length > 0) {
            setTimeout(function () {
                widget.addClass('-hover');
                setTimeout(function () {
                    widget.removeClass('-hover');
                }, 3000);
            }, 1000);

            setInterval(function () {
                widget.addClass('-active');
                setTimeout(function () {
                    widget.removeClass('-active');
                    setTimeout(function () {
                        widget.addClass('-active');
                        setTimeout(function () {
                            widget.removeClass('-active');
                        }, 300);
                    }, 300);
                }, 300);
            }, 5000);
        }
    };

    return {
        init: init
    }

})();


var Preloader = (function () {

    var $preloader = $('[data-role="preloader"]');

    var hide = function () {
        var d = $.Deferred();
        $preloader.addClass('hide-loader');
        d.resolve();
        return d.promise();
    };

    var init = function (callback) {
        /*$(window).load(function () {*/
        Promo.loadVideo();
        setTimeout(function () {
            hide().done(function () {
                callback();
            });
        }, 2800);
        /* });*/
    };

    return {
        init: init
    }

})();

var Menu = (function () {

    var $menu = $('[data-role="menu"]');
    var isOpen = false;

    var hide = function () {
        $menu.removeClass('is-open');
        $('html').removeClass('overflow-hidden');
        setTimeout(function () {
            $menu.animate({
                'opacity': 0
            }, {
                duration: 500,
                complete: function () {
                    $(this).css({'display': 'none'});
                    isOpen = false;
                }
            });
        }, 500);
    };

    var show = function () {
        $menu.css({'display': 'block'}).animate({'opacity': 1}, {
            duration: 500,
            complete: function () {
                $('html').addClass('overflow-hidden');
                $(this).addClass('is-open');
                isOpen = true;
            }
        })
    };

    var init = function () {
        $(document).on('click', '[data-role="header-menu-toggle"]', function (e) {
            e.preventDefault();
            if (isOpen) {
                hide();
            } else {
                show();
            }
        });
    };

    return {
        init: init
    }

})();

var Register = (function () {
    var init = function () {

        $('body').on('keydown', '.js-register-form', function (e) {
            if (e.keyCode == 13) {
                e.preventDefault();
                return false;
            }
        });

        $('body').on('click', '.js-get-sms-reg', function () {
            $('[data-role="msnet-modal-ajax-sub"]').text('');
            var $this = $(this);
            var $form = $this.closest('form');
            if (!myValidateForm($form)) {
                return false;
            }
            var data = $form.serializeObject();
            SendAjax('GET_PASS_CODE', data, function (data) {

                if (data.TYPE == 'ERROR') {
                    $('[data-role="msnet-modal-ajax-sub"]').text(data.MESSAGE);
                } else {
                    $this.closest('.form__button').fadeOut(200, function () {
                        $('.js-finish-reg').fadeIn(200, function () {
                            $('.js-finish-reg').find('input[name=PASSWORD], input[name=CODE]').addClass('req');
                            if (data.WARN_MESSAGE) {
                                $('[data-role="msnet-modal-ajax-sub"]').addClass('-warn').text(data.WARN_MESSAGE);
                            }

                        });
                    });
                }
            });
        });
    };
    return {
        init: init
    }
})();

var Password = (function () {
    var passOpen = false;
    var init = function () {
        $('body').on('click', '[data-role="show-password"]', function () {
            var $this = $(this);
            var type = passOpen ? 'password' : 'text';
            var inputPass = $this.siblings('input[data-pass="Y"]');
            if (inputPass.attr('disabled') === undefined) {
                $this.toggleClass('view-password--open');
                inputPass.attr('type', type);
                passOpen = !passOpen;
            }
        });

        $('body').on('click', '.js-change-pas', function (e) {
            e.preventDefault();
            $(this).prop("disabled", true).closest('form').find('input[data-pass="Y"], input[data-pass-confirm="Y"]').removeAttr('disabled')
        });

        $('body').on('input', 'input[data-pass="Y"]', function (e) {
            $(this).siblings('input[data-pass-confirm="Y"]').val($(this).val());
        });

    };
    return {
        init: init
    }
})();

var Garage = (function () {
    var delBtn = '.js-auto-delete';
    var selectBtn = '.js-auto-select';
    var autoItem = '.js-auto-item';
    var delHistoryBtn = '.js-auto-history-delete';
    var delOrdereBtn = '.js-auto-order-delete';

    var init = function () {

        if ($(delBtn).length) {
            $('body').on('click', delBtn, function (e) {
                if (confirm('Вы действительно хотите удалить этот автомобиль?')) {
                    var id = $(this).closest(autoItem).data('auto-id');
                    SendAjax('DELETE_AUTO', id, function (data) {
                        if (data.success) {
                            window.location = '/service/personal/garage/';
                        } else {
                            alert('При выполнении операции произошла ошибка.')
                        }
                    })
                }
            });
        }

        if ($(delHistoryBtn).length) {
            $('body').on('click', delHistoryBtn, function (e) {
                if (confirm('Вы действительно хотите удалить данную запись?')) {
                    var _data = {
                        'AUTO_ID': $(this).data('auto-id'),
                        'HISTORY_ID': $(this).data('hist-id'),
                    };
                    SendAjax('DELETE_HISTORY', _data, function (data) {
                        if (data.success) {
                            window.location = '';
                        } else {
                            alert('При выполнении операции произошла ошибка.')
                        }
                    })
                }
            });
        }

        if ($(delOrdereBtn).length) {
            $('body').on('click', delOrdereBtn, function (e) {
                if (confirm('Вы действительно хотите отменить данную заявку?')) {
                    var _data = {
                        'AUTO_ID': $(this).data('auto-id'),
                        'ORDER_ID': $(this).data('order-id'),
                    };
                    SendAjax('DELETE_ORDER', _data, function (data) {
                        if (data.success) {
                            window.location = '';
                        } else {
                            alert('При выполнении операции произошла ошибка.')
                        }
                    })
                }
            });
        }

        if ($(selectBtn).length) {
            $('body').on('click', selectBtn, function (e) {
                var id = $(this).closest(autoItem).data('auto-id');
                SendAjax('SELECT_AUTO', id, function (data) {
                    if (data.success) {
                        window.location.reload();
                    } else {
                        alert('При выполнении операции произошла ошибка.')
                    }
                });
            });
        }
        return {
            init: init
        }
    };
    return {
        init: init
    }
})();

$(function () {

    if (window.location.pathname === '/') {
        Preloader.init(function () {
            $('.header').addClass('animation-opacity-in');
            $('.promo__pagination-wrap').addClass('animation-opacity-in');
            $('.promo__scroll').addClass('animation-opacity-in');
            ConsultWidget.init();
        });
    } else {
        ConsultWidget.init();
    }

    Menu.init();
    Register.init();
    Password.init();
    Garage.init();

    adaptiveDropdownMenu.init($('.header__buttons'), function(menu) {
        return ((($('.header__menu-button').offset().left - 20 < menu.data.initialWidth) || $('.header__menu-button').offset().top > 0) && $('.header__menu-button').is(':visible'));
    }, '.header__button--active');

});


var adaptiveDropdownMenu = {
    elem: null,
    data: {
        initialWidth: 0,
        defInterval: 200,
        state: 'default',
        prefix: 'custom-',
        menuClass: 'dropdown-menu',
        activeClass: 'dropdown-active',
        wrapClass: 'dropdown-menu-wrapper',
        selectedClass: '',
    },
    init: function(elem, transformCondition, selectedClass) {
        this.elem = elem;

        this.data.initialWidth = $(this.elem).children().toArray().reduce((function(sum, element) {
            return sum + this.elemWidth(element);
        }).bind(this), 0);

        this.data.selectedClass = selectedClass;

        this.startWatching(transformCondition);
    },
    startWatching: function(condition) {
        var localState = 0;
        setInterval((function(){
            if (condition(this) && this.data.state == 'default')
                this.transform();
            else if (!condition(this) && this.data.state == 'dropdown')
                this.recover();
        }).bind(this), this.data.defInterval);
    },
    transform: function() {
        //menu > children  ====> menu > wrapper > children // menu.onClick => menu.toggle()
        this.data.state = 'dropdown';

        $(this.elem).prepend($(this.data.selectedClass).clone().attr('href', 'javscript:void(0)'));
        var children = $(this.elem).children();

        var wrapper = $('<div></div>').addClass(this.data.prefix + this.data.wrapClass).append(children);

        $(this.elem).children().remove();

        $(this.elem).append(wrapper).addClass(this.data.prefix + this.data.menuClass);

        var actualWrapper = $(this.elem).find('.' + this.data.prefix + this.data.wrapClass);
        var maxWidth = $(children).toArray().reduce((function(sum, elem) {
            if (sum < this.elemWidth(elem))
                return this.elemWidth(elem);
            else
                return sum;
        }).bind(this), 0);
        $(actualWrapper).css('width', maxWidth + 30);

        this.bindClick((function(e) {
            //e.preventDefault();
            $(e.target).parents('.' + this.data.prefix + this.data.menuClass).toggleClass(this.data.prefix + this.data.activeClass);
        }).bind(this));

        $('body').click((function(e) {
            if ($(e.target).parents('.' + this.data.prefix + this.data.menuClass).length == 0 && this.data.state == 'dropdown') {
                $('.' + this.data.prefix + this.data.menuClass).removeClass(this.data.prefix + this.data.activeClass);
            }
        }).bind(this));
    },
    recover: function() {
        //menu > wrapper > children  ====> menu > children
        this.data.state = 'default';

        var children = $(this.elem).find('.' + this.data.prefix + this.data.wrapClass).children().slice(1);

        $(this.elem).children().remove();
        $(this.elem).append(children);
        $(this.elem).removeClass(this.data.prefix + this.data.menuClass);

        this.bindClick(function(e) {});
    },
    bindClick: function(callback) {
        $(this.elem).on('click', function(e) {
            callback(e);
        });
    },
    elemWidth: function(elem) {
        return $(elem).width() + parseInt($(elem).css('margin-left')) + parseInt($(elem).css('margin-right')) + parseInt($(elem).css('padding-left')) + parseInt($(elem).css('padding-right'));
    },
};

$(document).ready(function () {
    $('.js-tooltip').hover(function () {
        var tooltip = $(this).find('.js-tooltip-info');
        $(tooltip).toggleClass('active');
    });
});

$(function() {
    $('input[autocomplete="off"]').each(function(i, e) {
        $(e).attr('readonly', 'readonly');
        $(e).on('focus', function(event) {
            $(event.target).removeAttr('readonly');
        })
    });
})